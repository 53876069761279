'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
var defaultOrder = {
  FIRST: 'asc',
  '': 'asc',
  asc: 'desc',
  desc: ''
};

exports.default = defaultOrder;